<div>
  <form class="col-lg-6" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
    <div class="form-row">
      <div class="form-group col-md-6">
        <label>
          {{ "contactUs.subject" | cxTranslate }}
          <span class="validationErrorText"> * </span></label
        >
        <select
          class="form-control"
          formControlName="subject"
          (change)="selectChangeHandler($event)"
          [ngStyle]="{
            'border-color':
              (this.myForm.value.subject == '' && submitted) ||
              (f['subject'].invalid && submitted)
                ? 'red'
                : ''
          }"
        >
          <option value="" disabled="disabled" selected="selected">
            {{ "contactUs.chooseASubject" | cxTranslate }}
          </option>
          <option>{{ "contactUs.accountIssues" | cxTranslate }}</option>
          <option>{{ "contactUs.orderStatus" | cxTranslate }}</option>
          <option>{{ "contactUs.shopHelp" | cxTranslate }}</option>
          <option>{{ "contactUs.technicalIssues" | cxTranslate }}</option>
          <option>{{ "contactUs.productList" | cxTranslate }}</option>
          <option>{{ "contactUs.other" | cxTranslate }}</option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col">
        <label
          >{{ "contactUs.email" | cxTranslate
          }}<span class="validationErrorText"> * </span></label
        >
        <input
          type="email"
          class="form-control email"
          formControlName="email"
          [ngStyle]="{
            'border-color':
              (this.myForm.value.email == '' && submitted) ||
              (f['email'].invalid && submitted) ||
              errorFlag
                ? 'red'
                : ''
          }"
        />
        <span class="validationErrorText" *ngIf="errorFlag">{{
          "contactUs.errorMessage" | cxTranslate
        }}</span>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col">
        <label
          >{{ "contactUs.message" | cxTranslate
          }}<span class="validationErrorText"> * </span></label
        >
        <textarea
          rows="6"
          maxlength="255"
          #textValue
          formControlName="message"
          class="form-control messagemodal"
          [ngClass]="{
            'is-invalid': submitted && f['message'].errors
          }"
          style="height: 201px"
          placeholder="{{ 'contactUs.charactersAllowed' | cxTranslate }}"
        >
        </textarea>
        <span class="form-row__description">
          {{ "contactUs.remainingCharacters" | cxTranslate }}
          {{ 255 - textValue.value.length }}
        </span>
      </div>
    </div>
    <div class="form-group">
      <label>{{ "contactUs.documents" | cxTranslate }}</label>
      <div class="DragDropUpload" style="line-height: 3.5">
        <div class="box drag-drop-enabled">
          <div class="box__input" (drop)="onDrop($event)" (dragover)="onDragOver($event)">
            <input
              type="file"
              id="file"
              multiple
              class="box__file"
              formControlName="document"
              (change)="uploadImage($event)"
              #fileUploader
            />
            <label for="file">
              <i data-v-172755ba="" class="sg-icon sg-icon-download cursor-pointer chooseAFile-icon"></i>
              <span class="select-file-label" style="display: block">{{
                "contactUs.chooseAFile" | cxTranslate
              }}</span>
            </label>
            <div
              class="file-listing"
              *ngFor="let res of uploadFiles; let i = index"
            >
              <div class="uploaded-file">
                <span class="file-name">{{ res.name }}</span>
                <span class="remove-file" (click)="remove(i)">
                  <i class="fa fa-times"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p style="color: red" class="mt-2" *ngIf="typeFlag">
        {{ "contactUs.invalidFile" | cxTranslate }}:{{ fileType }}
      </p>
      <div class="mt-3 mb-4 supportedFileType-text">
        {{ "contactUs.supportedFileType" | cxTranslate }}
      </div>
    </div>
    <div class="form-row mt-2">
      <div
        class="visualCaptchaContainer text-center mb-4"
        [ngClass]="
          statusvalueinvalid ? 'visualCaptchainvalid' : 'visualCaptcha'
        "
        *ngIf="this.validation && this.userId == 'anonymous'"
      >
        <span>
          <ngx-captcha [config]="captchaConfig"></ngx-captcha>
        </span>
      </div>
    </div>
    <div class="form-group mb-4 submit-btn">
      <button class="btn cstm-btn-primary" type="submit">
        {{ "contactUs.submit" | cxTranslate }}
      </button>
    </div>
  </form>
</div>

<div
  class="modal"
  tabindex="-1"
  id="exampleModal"
  role="dialog"
  data-backdrop="true"
  [ngStyle]="{ display: this.display }"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">
          {{ "contactUs.confirmation" | cxTranslate }}
        </h1>
        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="onCloseHandled()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="overflow: hidden">
        <p>{{ "contactUs.titleMsg" | cxTranslate }}</p>
      </div>
      <div class="modal-footer justify-content-start">
        <button
          type="button"
          class="btn cstm-btn-primary"
          data-dismiss="modal"
          (click)="navigate()"
        >
          {{ "contactUs.ok" | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</div>
