import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstants } from '../../../SGRE-shared/constants/app-constant';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContactUsService {
  private dataSubject = new BehaviorSubject<any>(null);
  data$ = this.dataSubject.asObservable();
  statuscode: string;

  constructor(private readonly http: HttpClient) {}

  contactusdetails(requestObject?) {
    const requestOptions = {
      url:
        environment.siteUrls.getBaseUrl +
        environment.siteUrls.getPrefix +
        AppConstants.apiUrls.contactusdetails,
    };
    return this.http.post(requestOptions.url, requestObject, {
      observe: 'response',
    });
  }
}
